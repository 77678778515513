/* 기본 레이아웃 스타일 */
.menuTitle {
  text-align: center;
  margin-bottom: 5%;
}

.mainContainerDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
}

/* Data Grid CSS */
.dataGrid .MuiDataGrid-row.Mui-selected {
  background-color: #ffcccc !important; /* 클릭된 행의 연한 톤 핑크 */
}

/* 버튼 스타일 */
.searchButton,
.addButton,
.saveButton,
.loginBtn,
.downloadButton {
  background: #ffffff; /* 배경 색상 없음 (뉴모피즘 스타일) */
  border: none; /* 기본 테두리 제거 */
  border-radius: 12px; /* 모서리 둥글기 */
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.1),
    -6px -6px 12px rgba(255, 255, 255, 0.7); /* 뉴모피즘 음영 효과 */
  color: #000; /* 글씨 색상 검은색으로 변경 */
  padding: 10px 20px; /* 안쪽 여백 */
  font-size: 16px; /* 폰트 크기 */
  cursor: pointer; /* 포인터 커서 */
  transition: all 0.2s ease; /* 부드러운 전환 효과 */
}

/* 버튼 호버 상태 */
.searchButton:hover,
.addButton:hover,
.saveButton:hover,
.downloadButton:hover {
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1),
    -4px -4px 8px rgba(255, 255, 255, 0.7); /* 호버 시 음영 효과 */
}

/* 버튼 클릭 상태 */
.searchButton:active,
.addButton:active,
.saveButton:active,
.downloadButton:active {
  box-shadow: inset 4px 4px 8px rgba(0, 0, 0, 0.2),
    inset -4px -4px 8px rgba(255, 255, 255, 0.5); /* 클릭 시 음영 효과 */
}

/* 입력 필드 스타일 */
.textField {
  background: #ffffff; /* 배경 색상 */
  border: none; /* 기본 테두리 제거 */
  border-radius: 12px; /* 모서리 둥글기 */
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.1),
    -6px -6px 12px rgba(255, 255, 255, 0.7); /* 뉴모피즘 음영 효과 */
  color: #333; /* 텍스트 색상 */
  padding: 10px; /* 안쪽 여백 */
  font-size: 16px; /* 폰트 크기 */
  transition: all 0.2s ease; /* 부드러운 전환 효과 */
}

/* 드롭다운 메뉴 스타일 */
.formControl .MuiSelect-select {
  background: #ffffff; /* 배경 색상 */
  border-radius: 12px; /* 모서리 둥글기 */
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.1),
    -6px -6px 12px rgba(255, 255, 255, 0.7); /* 뉴모피즘 음영 효과 */
  color: #333; /* 텍스트 색상 */
  padding: 10px; /* 안쪽 여백 */
  font-size: 16px; /* 폰트 크기 */
  min-width: 100px; /* 드롭다운의 최소 가로 길이 설정 */
  transition: all 0.2s ease; /* 부드러운 전환 효과 */
}

/* 드롭다운 항목 스타일 */
.MuiMenuItem-root {
  border-radius: 8px; /* 모서리 둥글기 */
  padding: 10px; /* 안쪽 여백 */
  font-size: 16px; /* 폰트 크기 */
}

/* 드롭다운 항목 호버 상태 */
.MuiMenuItem-root:hover {
  background: #f0f0f0; /* 호버 시 배경 색상 */
}

/* 드롭다운 항목 클릭 상태 */
.MuiMenuItem-root.Mui-selected {
  background: #dcdcdc; /* 클릭 시 배경 색상 */
}

/* 검색 조건 스타일 */
.actionBtns {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px;
  gap: 10px;
  justify-content: space-between;
}

.actionBtns .searchDiv {
  display: flex;
  align-items: center;
  gap: 10px; /* 입력 필드와 드롭다운 간의 간격 조정 */
}

.actionBtns .searchDiv .searchButton {
  margin-left: 10px;
}

.actionBtns .btnsDiv {
  display: flex;
  gap: 10px; /* 버튼 간의 간격 조정 */
}

/* 타입 버튼 컨테이너 스타일 */
.actionBtns .typeButtons {
  display: flex;
  border: 1px solid #ddd; /* 테두리 추가 */
  border-radius: 12px; /* 모서리 둥글기 */
  padding: 10px; /* 내부 여백 */
  background: #ffffff; /* 배경 색상 */
}

/* 타입 버튼 스타일 */
.actionBtns .typeButtons .typeButton {
  background: #ffffff; /* 배경 색상 */
  border: none; /* 테두리 제거 */
  border-radius: 8px; /* 모서리 둥글기 */
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1),
    -4px -4px 8px rgba(255, 255, 255, 0.7); /* 뉴모피즘 음영 효과 */
  color: #333; /* 텍스트 색상 */
  padding: 8px 16px; /* 패딩 */
  font-size: 14px; /* 폰트 크기 */
  cursor: pointer; /* 포인터 커서 */
  transition: all 0.2s ease; /* 부드러운 전환 효과 */
  margin: 0 5px; /* 버튼 간의 간격 */
  display: flex; /* 문구와 버튼을 한 줄에 배치 */
  align-items: center; /* 버튼과 문구 수직 정렬 */
}

/* 버튼 활성화 상태 */
.actionBtns .typeButtons .typeButton.active {
  background: #e0e0e0; /* 활성화된 버튼 배경 색상 */
  box-shadow: inset 4px 4px 8px rgba(0, 0, 0, 0.1),
    inset -4px -4px 8px rgba(255, 255, 255, 0.7); /* 활성화된 버튼 음영 효과 */
  color: #000; /* 활성화된 버튼 텍스트 색상 */
}

/* 타입 문구 스타일 */
.actionBtns .typeButtons .typeButton b {
  margin-right: 5px; /* 버튼과 문구 간의 간격 */
}

.noticeSpan {
  color: red;
}
