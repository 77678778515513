.loginDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7%;
}

.headerDiv {
  height: 22vh;
}

.formContainer {
  justify-content: center;
  border: 1px solid black;
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
  width: 20%;
}

.loginForm {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.loginInput {
  width: 100%;
  padding: 10px;
  border: 1px solid black;
  border-radius: 5px;
  margin-bottom: 10px;
}

.loginBtn {
  width: 100%;
  align-self: center;
  padding: 10px;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.loginLogo {
  width: 20%;
  height: 20%;
  margin-bottom: 10px;
}
