.addAgencyDiv {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.addAgencyDiv div {
  border-radius: 12px; /* 모서리 둥글기 */
  margin-bottom: 5px;
  margin-top: 1px;
}

.addAgencyDiv form b {
  margin-left: 5px;
}

.updated-row {
  background-color: #ffcccc !important;
}

.itemPriceDiv {
  display: flex;
}

.itemPriceDiv h3 {
  text-align: center;
}

.itemPriceDiv div {
  width: 100%;
  border-radius: 12px; /* 모서리 둥글기 */
}

.itemPriceDiv .novaItemDiv {
  margin-right: 20px;
}

.itemDiv {
  border: 1px solid lightgray; /* 얇은 검은색 테두리 추가 */
  margin-top: 10px;
  border-radius: 12px; /* 모서리 둥글기 */
  padding: 10px; /* 테두리 안쪽 여백 추가 */
}

.addAgencyBtnDiv {
  text-align: center;
  margin-top: 30px;
}
