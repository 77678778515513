.homeHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 20px); /* 전체 너비에서 양쪽 여백을 뺀 값으로 설정 */
  z-index: 1000;
  background-color: #ffffff;
}

.homeHeaderNav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px;
}

.headerLogoDiv {
  width: 10%;
}

.headerMenuDiv {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.headerUserDiv {
  display: flex;
  width: 15%;
  justify-content: flex-end;
  align-items: center;
}

.headerLogo {
  width: 7%;
  height: 7%;
  cursor: pointer;
}

.headerMenuItem {
  cursor: pointer;
  font-weight: normal;
  padding: 10px 20px; /* 버튼 안쪽 여백 */
  transition: all 0.3s ease;
  display: inline-block; /* 인라인 블록으로 공간 조정 */
  position: relative; /* 하위 요소의 위치를 기준으로 설정 */
}

/* active 상태 */
.headerMenuItem.active {
  font-weight: bold;
  color: #000; /* 텍스트 색상 */
  background: #ffffff; /* 배경 색상 */
  border-radius: 12px; /* 모서리 둥글기 */
  box-shadow: inset 4px 4px 8px rgba(0, 0, 0, 0.2),
    inset -4px -4px 8px rgba(255, 255, 255, 0.5); /* 뉴모피즘 음영 효과 */
}
